@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

p > a {
  color: #648eca;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
}
